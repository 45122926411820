import React from "react";

function StaticTag() {
  return (
    <div className="text-white p-2 ml-[10%] md:ml-[46%] lg:ml-[55%] xl:ml-[72%]">
      <h1 className="text-sm sm:text-sm md:text-lg lg:text-lg xl:text-lg ">10th SSC Maharashtra Board Question Paper</h1>
    </div>
  );
}

export default StaticTag;
 